<template>
 <div>
    <div class="card">
      <h3>订单回调</h3>
      <el-input v-model="flow" placeholder="订单号"></el-input>
      <el-button @click="payNOt">订单回调</el-button>
    </div>
   
    <div class="card">
      <h3>更新图片</h3>
      <p>操作：填写图片key-删除图片，删除新的图片</p>
      <p>https://img.curiousmore.com/图片key；如https://img.curiousmore.com/1717663210571.png的key为1717663210571.png</p>
      <el-input v-model="key" placeholder="图片key"></el-input>
      <el-button @click="delFile">删除图片</el-button>
      <el-upload
        class="avatar-uploader"
        action="http://up-z0.qiniup.com/"
        :data="QiNiYunL"
        :show-file-list="false"
        :on-success="successImg"
        :before-upload="getToken"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>

  
    <el-button @click="coachEs">coachEs</el-button>
     <el-button @click="meces">meces</el-button>
 <el-button @click="refreshESKB">refreshESKB</el-button>
      
<el-button @click="courseEs">courseEs</el-button>

<div class="card">
      <h3>删除redis</h3>
      <el-input v-model="r_key" placeholder="key"></el-input>
      <el-button @click="delredis">删除</el-button>
    </div>

     <el-button @click="imUserqueryListPage">imUserqueryListPage</el-button>

<el-button @click="esQuery">esQuery</el-button>
<div class="card">
      <h3>deepseek</h3>
      <el-input
  type="textarea"
  :rows="2"
  placeholder="请输入内容"
  v-model="content">
</el-input>
      <el-button @click="sendDS">发送</el-button>
      
    </div>
 <el-button @click="kbes">kbes</el-button>
 <el-button @click="kbesv">kbesv</el-button>
 <el-button @click="vectorSearch">vectorSearch</el-button>
 <el-button @click="delIndex">delIndex</el-button>
 <el-button @click="createVectorIndex">createVectorIndex</el-button>

 
  </div> 

</template>

<script>
import imageCropper from "../../components/imageCropper/imageCropper.vue";

export default {
  components: {
    imageCropper,
  },
  data() {
    return {
      env: process.env.NODE_ENV,
      flow: "",
      key: "",
      imageUrl: "",
      QiNiYunL: {
        key: "",
        token: "",
      },
      r_key:'',
      content:''
    };
  },

  mounted() {
    
  },
  methods: {
     delIndex(){
       this.$get('/chat/knowledgeBase/delIndex',{})
    },
     createVectorIndex(){
       this.$get('/chat/knowledgeBase/createVectorIndex',{})
    },
     vectorSearch(){
       this.$get('/chat/knowledgeBase/searchVector',{question:'保证金怎么退款'})
    },
    kbes(){
       this.$get('/chat/knowledgeBase/esQuery',{})
    },
    kbesv(){
       this.$get('/chat/knowledgeBase/queryVector',{})
    },

    // --------------------------------
    refreshESKB(){
      let data = {
         
      }
        this.$get('/chat/knowledgeBase/refreshES',data)
    },

     delredis(){
        this.$get('/user/sms/dr',{key:this.r_key}).then(res=>{
          this.r_key = ''
        })
    },
  
   
   courseEs() {
      this.$get("/user/masterSetPrice/insertEs", {});
    },
    meces() {
      this.$get("/user/mastermechanism/insertEs", {});
    },
    
   
    pushTest() {
      this.$get("/user/pushHistory/wxpusht", {});
    },

    coachEs() {
      this.$get("/user/coach/insertEs", {});
    },

    // resetDistrict(){
    //     this.$get("/user/district/updateDistrictInfo")
    // },
    // geteInfo() {
    //   this.$get("/user/esignTemp/getOrgIdentityInfo", { name: "杭州汇合" });
    //   this.$get("/user/esignTemp/getPsnIdentityInfo", { phone: "" });
    // },

    payNOt() {
      this.$axios({
        url: "/user/pay/iosPayNotify",
        data: {
          flowing_no: this.flow,
        },
        method: "post",
      }).then((res) => {
        this.$message("成功");
        this.flow = "";
      });
    },
    delFile() {
      if (!this.key) {
        this.$message("图片名称为空");
        return false;
      }
      this.$post("/push/upload/delFile", { key: this.key }).then((res) => {
        this.$message("删除成功");
      });
    },
    successImg(file) {
      this.imageUrl = this.upload_qiniu_addr + file.key;
      this.$message("上传成功");
    },
    getToken() {
      let _this = this;
      if (!this.key) {
        this.$message("图片名称为空");
        return false;
      }
      let data = {
        key: this.key,
        type: "pic",
      };
      return new Promise((resolve, reject) => {
        _this
          .$axios({
            method: "post",
            url: "https://www.curiousmore.com/qmore/push/upload/getToken",
            data: data,
          })
          .then((res) => {
            _this.QiNiYunL.token = res.data.data;
            _this.QiNiYunL.key = data.key;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}
.con-wrap {
  width: 100%;
  height: 4000px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgb(155, 211, 155),
    rgb(194, 51, 51)
  );
}
.m{
  font-size: 20px;
  height: 1000px;
}
</style>